// import AutoSubmit from '@stimulus-components/auto-submit'
import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash.debounce'

export default class extends Controller {
  static values = {
    delay: {
      type: Number,
      default: 150
    }
  }

  initialize () {
    this.submit = this.submit.bind(this)
  }

  connect () {
    this.form = document.getElementById(this.element.getAttribute('data-remote-form'))

    if (!this.form) {
      return
    }

    if (this.delayValue > 0) {
      this.submit = debounce(this.submit, this.delayValue)
    }
  }

  submit () {
    this.form.requestSubmit()
  }

  submitWithouDelay () {
    this.form.requestSubmit()
  }
}
