import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'submitButton']

  connect () {
    this.updateSubmitButtonState()
  }

  updateSubmitButtonState () {
    const isChecked = this.hasCheckedCheckbox()

    if (this.hasSUbmitButtonTarget) {
      this.submitButtonTarget.disabled = !isChecked
    }
  }

  hasCheckedCheckbox () {
    return this.checkboxTargets.some(checkbox => checkbox.checked && !checkbox.disabled)
  }

  toggleCheckbox () {
    this.updateSubmitButtonState()
  }
}
