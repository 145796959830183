import { Controller } from '@hotwired/stimulus'
import currency from 'currency.js'

export default class extends Controller {
  static targets = ['totalTripCost', 'totalAmount', 'currency', 'paymentIndex']
  static values = {
    totalAmount: { type: String, default: '0' },
    currency: { type: String, default: 'USD' }
  }

  totalAmountValueChanged () {
    this.#setTotalTripCost()
    setTimeout(() => {
      this.dispatch('totalChanged', { detail: { totalAmount: this.totalAmountValue, currency: this.currencyValue } })
    }, 10)
  }

  currencyValueChanged (event) {
    this.#setTotalTripCost()
    this.dispatch('currencyChange', { detail: { currency: this.currencyValue } })
  }

  paymentAdd () {
    this.totalAmountValueChanged()
    this.recalculateIndexes()
  }

  recalculateIndexes () {
    let visibleIndex = 0

    this.paymentIndexTargets.forEach((target) => {
      if (target.style.display !== 'none') {
        target.textContent = `#${visibleIndex + 1}`
        visibleIndex++
      }
    })
  }

  setTotalAmount (event) {
    this.totalAmountValue = event.target.value || '0'
  }

  setCurrency (event) {
    this.currencyValue = event.target.value || 'USD'
  }

  pullFromCostTab (event) {
    const modal = document.getElementById('turbo-heads-up')

    const isChecked = event.target.checked
    this.totalAmountTarget.disabled = isChecked
    this.currencyTarget.disabled = isChecked
    this.dispatch('onPullFromCostTabChanged')
    if (isChecked) {
      modal.showModal()
    }
  }

  #setTotalTripCost () {
    this.totalTripCostTarget.textContent = this.#stringToCurrency(this.totalAmountValue, this.currencyValue).format()
  }

  #stringToCurrency (value, symbol) {
    if (!symbol) {
      return currency(value, { pattern: '#', precision: 0, separator: ',' })
    }
    return currency(value, { symbol, pattern: '# !', precision: 0, separator: ',' })
  }
}
